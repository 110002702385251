<template>
    <v-container fluid>
        <PageHeaderSlot showBackButton> </PageHeaderSlot>


        <v-form ref="dataForm" @submit.prevent="save()">
            <v-card>
                <v-card-text class="px-2">
                    <v-row class="ma-0">
                        <!-- <v-col cols="12" sm="6" md="4" lg="3">
                            <FormInput 
                                label="課程編號" 
                                placeholder="課程編號"
                                :fieldValue.sync="formData.course_code" 
                                dense 
                                required
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></FormInput>
                        </v-col> -->

                        <v-col cols="12" sm="6" md="4" lg="3">
                            <FormSelect 
                                label="年級" 
                                placeholder="選擇年級"
                                :fieldValue.sync="formData.grade" 
                                dense 
                                :options="$gradeOptions"
                                required
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></FormSelect>
                        </v-col>

                        <v-col cols="12" sm="6" md="4" lg="3">
                            <FormSelect 
                                label="課程類別" 
                                placeholder="選擇課程類別"
                                :fieldValue.sync="formData.category_id" 
                                dense 
                                :options="categoryOptions"
                                required
                                @changed="formData.schools = []"
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></FormSelect>
                        </v-col>

                        <v-col cols="12" sm="6" md="4" lg="3">
                            <FormSelect 
                                label="分校" 
                                placeholder="選擇分校"
                                :fieldValue.sync="formData.schools" 
                                dense 
                                :options="tutorSchoolOptions"
                                required
                                isMultiple
                                :noDataText="$validate.DataValid(formData.category_id) ? '沒有選項' : '先選課程類別'"
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></FormSelect>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <div class="d-flex align-center">
                <v-btn type="submit" class="primary mt-4" text depressed :loading="$store.getters.isLoading">儲存</v-btn>
                <v-btn v-if="$validate.DataValid(id)" type="button" class="mt-4 ml-4" outlined color="error" text depressed :loading="$store.getters.isLoading" @click="handleDelete()">刪除</v-btn>
            </div>
        </v-form>

        <ConfirmDialog ref="confirmDeleteDialog" title="是否刪除此課程？" deleteDescription="是否確定要刪除此課程？此動作無法復原。"></ConfirmDialog>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import Datatable from '@/components/Datatable.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import FormInput from '@/components/formField/FormInput.vue';
import FormSelect from '@/components/formField/FormSelect.vue';

export default {
    name: 'CourseDetails',
    components: {
        Datatable,
        ConfirmDialog,
        FormInput,
        FormSelect,
    },
    computed: { 
        id() {
            const id = parseInt(this.$route.params.id);
            if (!isNaN(id)) {
                return id;
            }

            return null;
        },
        dispatchUpdateOnChange() {
            if (this.$validate.DataValid(this.id)) {
                return true;
            } else {
                return false;
            }
        },
        tutorSchoolOptions() {
            if (this.$validate.DataValid(this.tutorSchoolData) 
                && this.$validate.DataValid(this.categoryOptions)
                && this.$validate.DataValid(this.formData.category_id)) {
                const targetCate = this.categoryOptions.find(el => el.value === this.formData.category_id);
                if (targetCate) {
                    const validSchools = this.tutorSchoolData.filter(el => targetCate.schools.includes(el.id));
                    return validSchools.map(el => {
                        return {
                            value: el.id,
                            text: el.name
                        }
                    })
                }
            }

            return [];
        },
    },
    data: () => ({
        studentStatus: 'active',
        formData: {
            // course_code: '',
            grade: '',
            category_id: '',
            schools: [],
        },
        categoryOptions: [],

        tutorSchoolData: null,
    }),
    methods: {
        ...mapActions(['setDialogMessage', 'setShowDialog', 'setLeaveDialogLink', 'setShowLeaveDialog']),
        async getCourseById() {
            try {
                const data = await this.$Fetcher.GetCourseById(parseInt(this.id));
                for(const key in this.formData) {
                    if (this.$validate.DataValid(data[key])) {
                        this.formData[key] = data[key];
                    }
                }             
            } catch(err) {
                this.$common.error(err);
                this.setDialogMessage({
                    message: 'noData',
                    returnLink: {name: 'CourseList'}
                });
                this.setShowDialog(true);
            } finally {
                this.$store.dispatch('toggleLoadingPage', false);
            }
        },

        async save() {
            if (this.$store.getters.isLoading) {
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: 'processing',
                    type: 'error',
                    refresh: false,
                    redirect: ''
                });
                return;
            }

            this.$store.dispatch('setLoading', true);

            const valid = this.$refs['dataForm'].validate();
            if (!valid) {
                this.$store.dispatch('setLoading', false);
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: 'required',
                    type: 'error',
                    refresh: false,
                    redirect: ''
                });
                return;
            }

            const payload = {
                // course_code: this.formData.course_code,
                grade: this.formData.grade,
                category_id: this.formData.category_id,
                schools: this.formData.schools,
            };
            if (this.$validate.DataValid(this.id)) { // edit
                try {
                    await this.$Fetcher.UpdateCourse(this.id, payload);
                    this.$store.dispatch('setDataIsUpdated', false);
                    this.$store.dispatch('toggleAlertMessage', {
                        show: true,
                        message: '儲存成功',
                        type: 'success',
                        refresh: true,
                        redirect: ''
                    });
                } catch(err) {
                    this.$common.error(err);
                    this.setDialogMessage({
                        title: "儲存失敗",
                        message: err,
                        isError: true,
                        returnLink: null,
                    });
                    this.setShowDialog(true);
                } finally {
                    this.$store.dispatch('setLoading', false);
                }

            } else { // create
                try {
                    await this.$Fetcher.NewCourse(payload);
                    this.$store.dispatch('setDataIsUpdated', false);
                    this.$store.dispatch('toggleAlertMessage', {
                        show: true,
                        message: '新增成功',
                        type: 'success',
                        refresh: false,
                        redirect: 'CourseList'
                    });
                   
                } catch (err) {
                    this.$common.error(err);
                    this.setDialogMessage({
                        title: "新增失敗",
                        message: err,
                        isError: true,
                        returnLink: null,
                    });
                    this.setShowDialog(true);
                } finally {
                    this.$store.dispatch('setLoading', false);
                }
            }
        },
     

        async handleDelete() {
            const confirm = await this.$refs.confirmDeleteDialog.show();
            if (confirm) {
                this.$store.dispatch('setLoading', true);
              
                try {
                    await this.$Fetcher.DeleteCourse(this.id);
                    this.$store.dispatch('toggleAlertMessage', {
                        show: true,
                        message: '刪除成功',
                        type: 'success',
                        refresh: false,
                        redirect: 'CourseList'
                    });
                } catch (err) {
                    this.$common.error(err);
                    this.setDialogMessage({
                        title: "刪除失敗",
                        message: err,
                        isError: true,
                        returnLink: null,
                    });
                    this.setShowDialog(true);
                } finally {
                    this.$store.dispatch('setLoading', false);
                }
            }
        },

        // ======== 分校 Options =======
        async getAllSchoolData() {
            try {
                const { data } = await this.$Fetcher.GetSchools();
                const filtered = data.filter(el => el.active === true);
                this.tutorSchoolData = filtered;
            } catch(err) {
                this.$common.error(err);
            }
        },

        // ======== 類別 Options =======
        async getAllCourseCategoryOptions() {
            try {
                let payload = {};
                const selectedSchool = await this.getUserSelectedSchool();
                if (this.$validate.DataValid(selectedSchool)) {
                    payload.center_id = selectedSchool;
                }

                const { data } = await this.$Fetcher.GetCourseCategories(payload);
                const filtered = data.filter(el => el.active === true);
                    if (this.$validate.DataValid(filtered)) {
                        this.categoryOptions = filtered.map(el => {
                        return {
                            value: el.id,
                            text: el.name,
                            schools: el.schools
                        }
                    })
                }
            } catch(err) {
                this.$common.error(err);
            }
        },
    },
    async mounted() {
        await this.getAllSchoolData();
        await this.getAllCourseCategoryOptions();
    },

    async created() {
        this.$store.dispatch('toggleLoadingPage', true);

        if (this.$validate.DataValid(this.id)) { // edit
            this.getCourseById();
        } else { // new
            this.$store.dispatch('toggleLoadingPage', false);
        }

    },

    // ------ navigation guard ------
    beforeRouteLeave(to, from, next) {
        if (this.$store.getters.isLoading) {
            this.$store.dispatch('toggleAlertMessage', {
                show: true,
                message: 'processing',
                type: 'error',
                refresh: false,
                redirect: ''
            });
            next(false);
        } else if (this.$store.getters.dataIsUpdated) {
            this.setLeaveDialogLink({ name: to.name });
            this.setShowLeaveDialog(true);
            next(false);
        } else {
            next();
        }
    },
}
</script>